.body {
    background-color: #f2f2f2;
    background: linear-gradient(to top, #f2f2f2 0%, transparent 100%), url("../assets/DiamondPattern.png");
    background-attachment: fixed, scroll;
    background-repeat: repeat;
    background-size: 250px;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
}

/* MAIN SECTION */

.main-container {
    max-width: 60em;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding: 0;
    overflow-x: hidden;
    color: black;
    text-align: center;
}

.secondary-container {
    padding: 12px;
    overflow-x: hidden;
    width: auto;
}

.landing-hr {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    border: 0;
    border-top: 0.0625rem solid rgba(0, 0, 0, .1);
    width: 85%;
}

.enchanted-logo {
    width: 67%;
    padding-left: 12px;
    padding-right: 12px;
}

.motto-text {
    font-style: italic;
    font-size: larger;
}

.card {
    max-width: 40em;
    background-color: white;
    border-radius: 15px;
    border: #f2f2f2 solid 2px;
}

.card-img {
    border-radius: 15px;
}

.google-play-badge {
    margin-left: auto;
    margin-right: auto;
    margin-top: 24px;
    margin-bottom: 24px;
    width: 40%;
}

.google-play-badge:hover {
    transform: scale(.95);
}

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    height: 0;
}

.video-container iframe {
    position: absolute;
    top: 3%;
    left: 2.5%;
    width: 95%;
    height: 93%;
    border-radius: 10px;
}

.video-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

/* NAV */

.nav-bar {
    padding-top: .5em;
    padding-bottom: .5em;
    background-color: white;
    height: 65px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;

}

.nav-container {
    max-width: 60em;
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between;
}

.nav-logo {
    height: 40px;
}

/* FOOTER */

.footer {
    color: black;
    width: 100%;
    margin-top: 24px;
    margin-bottom: 24px;
}

/* GENERAL */

.container-fluid {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x)* .5);
    padding-left: calc(var(--bs-gutter-x)* .5);
    margin-right: auto;
    margin-left: auto;
}

/* PRIVACY POLICY */

.simple-text {
    background-color: #eee;
    border: 1px solid black;
    padding: 5px;
    text-indent: 0pt;
    line-height: 114%;
    text-align: left;
    margin-bottom: 0px;
}

.simple-text p {
    margin: 0px;
}

.privacy-container h1 {
    color: #212C37;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 43.5pt;
}

.privacy-container .s1 {
    color: #212C37;
    font-family: Arial, sans-serif;
    font-style: italic;
    font-weight: bold;
    text-decoration: none;
    font-size: 11.5pt;
}

.privacy-container .s2 {
    color: #212C37;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 11.5pt;
}

.privacy-container .s3 {
    color: #212C37;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 11.5pt;
}

.privacy-container .s4 {
    color: #212C37;
    font-family: "Arial", sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 19pt;
    text-align: left;
}

.privacy-container .s5 {
    color: #212C37;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 11.5pt;
}

.privacy-container p {
    color: #212B37;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 11.5pt;
    text-align: left;
    margin-bottom: 20px;
}

.privacy-container h2 {
    color: #212B37;
    font-family: "Arial", sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 19pt;
}

.privacy-container h3 {
    color: #212B37;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 11.5pt;
    text-align: left;
    margin-bottom: 5px;
}

.privacy-container a {
    color: #3B78D7;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 11.5pt;
}

.privacy-container .s6 {
    color: #DB0505;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 11.5pt;
}

.privacy-container .s7 {
    color: #212B37;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 11.5pt;
}

.privacy-container .s8 {
    color: #201D29;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10.5pt;
}

.privacy-container .s10 {
    color: #212B37;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 11.5pt;
}

.privacy-container .s12 {
    color: #212B37;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 11.5pt;
}

.privacy-container .s13 {
    color: #DB0505;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 11.5pt;
}

.privacy-container ul {
    text-align: left;
    padding-left: 40px;
}

.privacy-container .term-table table {
    border: 1px solid black;
    width: 100%;
}

.privacy-container .term-table .term-head {
    text-align: center;
}

.privacy-container .term-table td {
    text-align: left;
    vertical-align: top;
    width: 33%;
    border: 1px solid #555;
    padding: 10px;
}